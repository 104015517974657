import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Divider,
  Alert,
  Avatar,
  Snackbar,
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import { DatePicker } from '../../form-inputs';
import Row from '../../Row';
import { Description as DescriptionIcon } from '@mui/icons-material';
import PdfViewer from '../../pdf-viewer/PdfViewer';
import { usePatientWebSocket } from '../../../hooks';

const extractPdfId = (url) => {
  const match = url.match(/\/([^\/]+)\.pdf/);
  return match ? match[1] : null;
};

const DateRangeAndReportSelector = ({
  formik,
  isFetching,
  isError,
  data,
  t,
  reportType,
  setReportType,
  selectedReport,
}) => (
  <>
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 1 }}>
      {t('Select Date Range')}
    </Typography>
    <Row>
      <DatePicker label={t('Start Date')} formik={formik} disableFuture name="startDate" />
      <DatePicker label={t('End Date')} disableFuture formik={formik} name="endDate" />
    </Row>

    {isFetching ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
        <CircularProgress />
      </Box>
    ) : isError ? (
      <Alert severity="error" sx={{ mt: 2 }}>
        {t('Error loading report types. Please try again.')}
      </Alert>
    ) : !data || data.length === 0 ? (
      <Box sx={{ py: 3, mt: 2, textAlign: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Avatar sx={{ bgcolor: 'grey.200', width: 60, height: 60 }}>
            <DescriptionIcon sx={{ fontSize: 36, color: 'grey.500' }} />
          </Avatar>
        </Box>
        <Typography variant="h6" gutterBottom>
          {t('No Report Templates Available')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('There are no report templates configured for this patient. Please contact your administrator.')}
        </Typography>
      </Box>
    ) : (
      <>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 3 }}>
          {t('Select Report Type')}
        </Typography>
        <FormControl variant="outlined" fullWidth sx={{ mb: 3 }}>
          <InputLabel id="report-type-label">{t('Report Type')}</InputLabel>
          <Select
            labelId="report-type-label"
            id="report-type-select"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            label={t('Report Type')}
          >
            {data.map((report) => (
              <MenuItem key={report.id} value={report.id}>
                {report.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedReport && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                {t('Report Details')}
              </Typography>
              <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default' }}>
                <Typography variant="body1">
                  {selectedReport.description || t('No description available for this report template.')}
                </Typography>
              </Paper>
            </Box>
          </>
        )}
      </>
    )}
  </>
);

const ReportGeneratingStatus = ({ t }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      py: 4,
    }}
  >
    <CircularProgress size={50} sx={{ color: 'primary.main', mb: 2 }} />

    <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 1 }}>
      {t('Generating Report...')}
    </Typography>

    <Typography variant="body2" color="textSecondary">
      {t('Please wait while we process your request.')}
    </Typography>
  </Box>
);

const GenerateReportDialog = (props) => {
  const { open, handleClose, patientUuid } = props;
  const { i18n, t } = useTranslation();
  const [reportType, setReportType] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');
  const [currentOperationId, setCurrentOperationId] = useState(null);
  const [processedReports, setProcessedReports] = useState(new Set());
  const [isGenerating, setIsGenerating] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [openGeneratedReportMessage, setOpenGeneratedReportMessage] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: '', severity: 'success' });
  const { patientReport, patientReportClinic } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const [postReport, result] = patientReport.add();

  const { data: patientReportData, refetch } = patientReport.list({
    patient: patientUuid,
    clinic: clinic.id,
  });

  const { data, isFetching, isError } = patientReportClinic.list({
    clinic: clinic.id,
    role: 'patient',
  });

  // Reset selected report type when dialog opens or when available reports change
  useEffect(() => {
    if (open && data && data.length > 0) {
      setReportType(data[0].id);
    } else {
      setReportType('');
    }
  }, [open, data]);

  const { lastJsonMessage, readyState } = usePatientWebSocket();

  // Find the currently selected report to display details
  const selectedReport = data?.find((report) => report.id === reportType) || null;

  // WebSocket Logic to handle report status updates
  useEffect(() => {
    if (isCancelled) return;
    if (readyState !== 1) {
      console.log('⚠️ WebSocket not ready. Current state:', readyState);
      return;
    }
    if (!lastJsonMessage?.entity || lastJsonMessage.entity !== 'PatientReport') return;

    const { id, status } = lastJsonMessage.data;

    if (id !== currentOperationId) return;
    if (processedReports.has(id)) return;

    if (status === 'completed') {
      refetch();

      const matchingReport = patientReportData?.find((report) => report.id === id);

      if (matchingReport?.file) {
        setIframeUrl(matchingReport.file);
        setOpenGeneratedReportMessage(false);
        setProcessedReports(new Set([...processedReports, id]));
        setIsGenerating(false);
        handleClose();
      }
    } else if (status === 'in-progress') {
      console.log('🔄 Report is in progress:', id);
      setIsGenerating(true);
      // setOpenGeneratedReportMessage(true);
      // setSnackbar({ message: t('Report generation in progress...'), severity: 'info' });
    } else if (status === 'error') {
      console.error('❌ Report generation failed:', id);
      setSnackbar({ message: t('Failed to generate report. Please try again.'), severity: 'error' });
      setOpenGeneratedReportMessage(true);
      setIsGenerating(false);
      handleClose();
    }
  }, [lastJsonMessage, patientReportData, readyState]);

  const handleSubmit = async (values) => {
    setIsCancelled(false);
    setIsGenerating(true);
    const body = {
      type: 'pdf',
      report_clinic: reportType,
      patient: patientUuid,
      language: i18n.resolvedLanguage,
      status: 'pending',
      data: {
        params_values: {
          start_date: moment(values.startDate).toISOString(),
          end_date: moment(values.endDate).toISOString(),
        },
      },
    };
    try {
      const response = await postReport({ body });

      const reportId = response?.data?.id;
      console.log();

      if (reportId) {
        setCurrentOperationId(reportId);
      }

      setSnackbar({ message: t('Report request sent. Please wait...'), severity: 'info' });
    } catch (error) {
      setSnackbar({ message: t('Failed to generate report. Please try again.'), severity: 'error' });
      setIsGenerating(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      startDate: moment().subtract(1, 'months'),
      endDate: moment(),
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const closeIframe = () => {
    setIframeUrl('');
  };

  const handleCloseGeneratedReportMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenGeneratedReportMessage(false);
  };

  const handleCancel = () => {
    handleClose();
    if (isGenerating) {
      setCurrentOperationId(null);
      setIsCancelled(true);
      setOpenGeneratedReportMessage(false);
      setTimeout(() => {
        setIsGenerating(false);
      }, 300);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ pb: 1 }}>{t('Generate Patient Report')}</DialogTitle>
        <DialogContent sx={{ pb: 1 }}>
          {isGenerating ? (
            <ReportGeneratingStatus t={t} />
          ) : (
            <DateRangeAndReportSelector
              formik={formik}
              isFetching={isFetching}
              isError={isError}
              data={data}
              t={t}
              reportType={reportType}
              setReportType={setReportType}
              selectedReport={selectedReport}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCancel} variant={isGenerating ? 'contained' : 'outlined'}>
            {isGenerating ? t('Close') : t('Cancel')}
          </Button>
          {!isGenerating && (
            <LoadingButton
              onClick={formik.handleSubmit}
              loading={result.isLoading}
              variant="contained"
              disabled={!reportType || result.isLoading || isFetching || isError || (data && data.length === 0)}
            >
              {t('Generate')}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(iframeUrl)}
        onClose={closeIframe}
        fullScreen
        sx={{
          '& .MuiDialog-paper': { backgroundColor: 'rgba(0, 0, 0, 0)' },
        }}
      >
        <PdfViewer link={iframeUrl} setIframeUrl={setIframeUrl} id={extractPdfId(iframeUrl)} />
      </Dialog>
      <Snackbar
        open={openGeneratedReportMessage}
        autoHideDuration={6000}
        onClose={handleCloseGeneratedReportMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseGeneratedReportMessage}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GenerateReportDialog;
